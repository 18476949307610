import React, { useEffect, useState, useCallback } from "react";
import styles from "./PhotoGrid.module.css";

const TOKEN = `${process.env.REACT_APP_INSTA_TOKEN}`;

const PhotoGrid = () => {
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [limit, setLimit] = useState(9);

  useEffect(() => {
    setLoading(true);
    fetch(`https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${TOKEN}&limit=${limit}`)
      .then(response => response.json())
      .then(results => {
        const { data } = results;
        setLoading(false);
        setPhotos(data);
      });
  }, [limit]);

  const handleClick = useCallback(
    () => {
      setLimit(limit + 3);
    },
    [limit],
  );

  return (
    <main className={`container ${styles.mainContainer}`}>
      <div className={styles.photoContent}>
        {photos?.length
          ? photos
              .filter(photo => ['CAROUSEL_ALBUM', 'IMAGE'].includes(photo.media_type))
              .map((photo, index) => (
                <a target="_blank" key={`${photo.id}-${index}`} href={photo.permalink} rel="noopener noreferrer">
                  <img title={photo.caption} src={photo.media_url} alt={photo.username} />
                </a>
              ))
          : 'Carregando'
        }
      </div>
      <button
        className={`${styles.button} ${loading ? `${styles.desabilitado}` : ""}`}
        disabled={loading}
        onClick={handleClick}
      >
        {loading ? "Carregando postagens..." : 'Carregar mais'}
      </button>
    </main>
  );
};

export default PhotoGrid;
