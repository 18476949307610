import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Sobre from "./Pages/Sobre/Sobre";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";

import "./App.css";
import Modal from "./Components/Modal";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Modal/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre" element={<Sobre />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
